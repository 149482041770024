import { of, Observable, ReplaySubject, forkJoin } from 'rxjs';
import { finalize, delay, map, tap } from 'rxjs/operators';
function typeToStorage(memoryType) {
  switch (memoryType) {
    case 'localStorage':
      return localStorage;
    case 'sessionStorage':
      return sessionStorage;
    default:
      throw new Error(`Unsupported memoryType: ${memoryType}`);
  }
}
function createCacheKey(key) {
  return `__fut_persistence_cache_${key}`;
}
const CACHE_UNIQUE_KEY_CHECK = new Set();
class CallCachePersistence {
  constructor(key, call, tryAgainOnError = false, memoryType = 'localStorage') {
    this.key = key;
    this.call = call;
    this.tryAgainOnError = tryAgainOnError;
    this.memoryType = memoryType;
    this.cacheKey = createCacheKey(key);
    if (CACHE_UNIQUE_KEY_CHECK.has(this.cacheKey)) {
      throw new Error(`Found duplicate key: ${key}`);
    }
    CACHE_UNIQUE_KEY_CHECK.add(this.cacheKey);
    this.storage = typeToStorage(memoryType);
  }
  get response() {
    const cachedResponse = this.readFromStorage();
    if (cachedResponse) {
      if (cachedResponse.content) {
        return of(cachedResponse.content);
      }
      if (cachedResponse.error) {
        return new Observable(subscriber => subscriber.error(cachedResponse.error));
      }
    }
    if (!this.tmpResponse) {
      this.tmpResponse = this.prepareCall();
    }
    return this.tmpResponse.asObservable();
  }
  update(value) {
    this.tmpResponse?.next(value);
    this.reset();
    this.writeOnStorage({
      content: value
    });
    return this.response;
  }
  reset() {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
    try {
      this.tmpResponse?.complete();
    } catch (e) {
      // Nope
    }
    this.tmpResponse = undefined;
    this.storage.removeItem(this.cacheKey);
  }
  prepareCall() {
    const tmpResponse = new ReplaySubject(1);
    this.tmpResponse = tmpResponse;
    this.subscription = this.call().pipe(finalize(() => {
      this.subscription = undefined;
      this.tmpResponse = undefined;
    })).subscribe({
      next: value => {
        tmpResponse?.next(value);
        tmpResponse?.complete();
        this.writeOnStorage({
          content: value
        });
      },
      error: error => {
        tmpResponse?.error(error);
        if (this.tryAgainOnError) {
          this.tmpResponse = undefined;
        } else {
          this.writeOnStorage({
            error
          });
        }
      }
    });
    return tmpResponse;
  }
  readFromStorage() {
    const rawData = this.storage.getItem(this.cacheKey);
    if (!rawData) {
      return null;
    }
    return JSON.parse(rawData);
  }
  writeOnStorage(result) {
    const rawData = JSON.stringify(result);
    this.storage.setItem(this.cacheKey, rawData);
  }
}
class CacheObservablePersistence {
  /**
   * @param {number} [expireTime] - The expiration time in milliseconds.
   * @param {number} [responseDelay] - The cached response delay in milliseconds.
   * @param {MemoryType} [memoryType] - The browser memory where store data
   */
  constructor(expireTime, responseDelay, memoryType = 'localStorage') {
    this.expireTime = expireTime;
    this.responseDelay = responseDelay;
    this.memoryType = memoryType;
    this.cache = new Map();
    this.storage = typeToStorage(memoryType);
  }
  static getExpireKey(key) {
    return `${createCacheKey(key)}_expire`;
  }
  get(key, call, tryAgainOnError) {
    let cachePersistence = this.getOrCreate(key, call, tryAgainOnError);
    const expireDate = this.getExpireDate(key);
    if (expireDate && expireDate < new Date()) {
      cachePersistence?.reset();
      this.cache.delete(key);
      cachePersistence = this.getOrCreate(key, call, tryAgainOnError);
    }
    return cachePersistence.response;
  }
  update(key, value) {
    const cachedValue = this.cache.get(key);
    if (!cachedValue) {
      console.debug(`Cache with key ${key} not found`);
      return this.getOrCreate(key, () => of(value)).response;
    }
    const response = cachedValue.update(value);
    this.initExpireLogic(key, true);
    return response;
  }
  delete(key) {
    const cachedValue = this.cache.get(key);
    if (cachedValue) {
      cachedValue.reset();
      this.cache.delete(key);
    }
    const expireKey = CacheObservablePersistence.getExpireKey(key);
    this.storage.removeItem(expireKey);
  }
  clear() {
    this.cache.forEach(value => {
      const key = value.key;
      this.storage.removeItem(CacheObservablePersistence.getExpireKey(key));
      value.reset();
      CACHE_UNIQUE_KEY_CHECK.delete(createCacheKey(key));
    });
    this.cache.clear();
  }
  getOrCreate(key, call, tryAgainOnError) {
    const cachedValue = this.cache.get(key);
    if (cachedValue) {
      return cachedValue;
    }
    if (this.responseDelay) {
      const responseDelay = this.responseDelay;
      call = () => forkJoin([call(), of('delay').pipe(delay(responseDelay))]).pipe(map(([r]) => r));
    }
    const cachePersistence = new CallCachePersistence(`${key}`, call, tryAgainOnError, this.memoryType);
    this.cache.set(key, cachePersistence);
    this.initExpireLogic(key);
    return cachePersistence;
  }
  initExpireLogic(key, update = false) {
    if (!this.expireTime || this.expireTime <= 0) {
      return;
    }
    if (!update) {
      const oldDate = this.getExpireDate(key);
      if (oldDate) {
        return;
      }
    }
    const expireKey = CacheObservablePersistence.getExpireKey(key);
    const expireDate = new Date();
    expireDate.setMilliseconds(expireDate.getMilliseconds() + this.expireTime);
    this.storage.setItem(expireKey, JSON.stringify(expireDate));
  }
  getExpireDate(key) {
    const expireKey = CacheObservablePersistence.getExpireKey(key);
    const rawDate = this.storage.getItem(expireKey);
    if (!rawDate) {
      return null;
    }
    return new Date(JSON.parse(rawDate));
  }
}
class CallCacheSimple {
  constructor(call, tryAgainOnError = false) {
    this.call = call;
    this.tryAgainOnError = tryAgainOnError;
  }
  get response() {
    if (this.error) {
      return new Observable(subscriber => subscriber.error(this.error));
    }
    if (this.internalResponse) {
      return of(this.internalResponse);
    }
    if (!this.tmpResponse) {
      this.tmpResponse = this.prepareCall();
    }
    return this.tmpResponse.asObservable();
  }
  update(value) {
    this.tmpResponse?.next(value);
    this.reset();
    this.internalResponse = value;
    return this.response;
  }
  reset() {
    this.error = undefined;
    this.internalResponse = undefined;
    this.subscription?.unsubscribe();
    this.subscription = undefined;
    try {
      this.tmpResponse?.complete();
    } catch (e) {
      // Nope
    }
    this.tmpResponse = undefined;
  }
  prepareCall() {
    const tmpResponse = new ReplaySubject(1);
    this.tmpResponse = tmpResponse;
    this.subscription = this.call().pipe(finalize(() => {
      this.subscription = undefined;
      this.tmpResponse = undefined;
    })).subscribe({
      next: value => {
        tmpResponse?.next(value);
        tmpResponse?.complete();
        this.internalResponse = value;
      },
      error: error => {
        tmpResponse?.error(error);
        if (this.tryAgainOnError) {
          this.tmpResponse = undefined;
        } else {
          this.error = error;
        }
      }
    });
    return tmpResponse;
  }
}
class CacheObservableSimple {
  /**
   * @param {number} [expireTime] - The expiration time in milliseconds.
   * @param {number} [responseDelay] - The cached response delay in milliseconds.
   */
  constructor(expireTime, responseDelay) {
    this.expireTime = expireTime;
    this.responseDelay = responseDelay;
    this.cache = new Map();
    if (this.expireTime) {
      this.timerCache = new Map();
    }
  }
  get(key, call, tryAgainOnError = false) {
    return this.getCall(key) ?? this.addCall(key, call, tryAgainOnError);
  }
  update(key, value) {
    const cachedValue = this.cache.get(key);
    if (!cachedValue) {
      console.debug(`Cache with key ${key} not found`);
      return this.addCall(key, () => of(value));
    }
    const response = cachedValue.update(value);
    if (!this.timerCache) {
      return response;
    }
    const timeout = this.timerCache.get(key);
    if (timeout) {
      clearTimeout(timeout);
      this.timerCache?.delete(key);
    }
    this.setExpireLogic(key, cachedValue);
    return response;
  }
  delete(key) {
    const cachedValue = this.cache.get(key);
    if (!cachedValue) {
      return;
    }
    cachedValue.reset();
    this.cache.delete(key);
    if (!this.timerCache) {
      return;
    }
    const timeout = this.timerCache.get(key);
    if (timeout) {
      clearTimeout(timeout);
      this.timerCache?.delete(key);
    }
  }
  clear() {
    this.cache.forEach(value => value.reset());
    this.cache.clear();
    if (!this.timerCache) {
      return;
    }
    this.timerCache.forEach(value => clearTimeout(value));
  }
  addCall(key, call, tryAgainOnError = false) {
    this.cache.get(key)?.reset();
    const timer = this.timerCache?.get(key);
    if (timer != null) {
      clearTimeout(timer);
      this.timerCache?.delete(key);
    }
    const callCache = new CallCacheSimple(call, tryAgainOnError);
    this.cache.set(key, callCache);
    return this.getObservable(key, callCache);
  }
  getCall(key) {
    const call = this.cache.get(key);
    if (!call) {
      return undefined;
    }
    return this.getObservable(key, call);
  }
  getObservable(key, call) {
    const cachedResponse = call.response.pipe(tap(() => {
      this.setExpireLogic(key, call);
    }));
    if (this.responseDelay) {
      return forkJoin([cachedResponse, of('delay').pipe(delay(this.responseDelay))]).pipe(map(([r]) => r));
    }
    return cachedResponse;
  }
  setExpireLogic(key, call) {
    if (!this.expireTime) {
      return;
    }
    if (!this.timerCache) {
      return;
    }
    const timerCache = this.timerCache;
    if (timerCache.has(key)) {
      return;
    }
    const timeout = setTimeout(() => {
      call?.reset();
      this.cache.delete(key);
      timerCache.delete(key);
    }, this.expireTime);
    timerCache.set(key, timeout);
  }
}
class CacheBuilder {
  constructor(memoryType) {
    this.memoryType = memoryType;
  }
  /**
   * It will create an in memory cache. It will delete after a page reload
   */
  static simple() {
    return new CacheBuilder();
  }
  /**
   * It will create a persistence cache. It will not delete after a page reload
   * @param {MemoryType} [memoryType] - The browser memory where store data
   */
  static persistence(memoryType = 'localStorage') {
    return new CacheBuilder(memoryType);
  }
  /**
   *
   * @param {number} [expireTime] - The expiration time in milliseconds.
   */
  setExpireTime(expireTime) {
    this.expireTime = expireTime;
    return this;
  }
  /**
   *
   * @param {number} [responseDelay] - The cached response delay in milliseconds.
   */
  setResponseDelay(responseDelay) {
    this.responseDelay = responseDelay;
    return this;
  }
  build() {
    if (!this.memoryType) {
      return new CacheObservableSimple(this.expireTime, this.responseDelay);
    }
    return new CacheObservablePersistence(this.expireTime, this.responseDelay, this.memoryType);
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { CacheBuilder, CacheObservablePersistence, CacheObservableSimple, CallCachePersistence, CallCacheSimple };
