import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { Config, ConfigSimulationModel, ConfigSimulationName, ConfigUiModel, ConfigUiName } from './config.model';
import { deepCopy } from '@futura/futura-ui/core';
import { CacheBuilder } from '@futura/futura-ui/cache';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly configSimulationCache = CacheBuilder.simple<ConfigSimulationName, unknown>().build();
  private readonly configUiCache = CacheBuilder.simple<ConfigUiName, unknown>().build();

  private readonly simulationsResponse = new ReplaySubject<Record<string, any>>(1);
  private readonly uiResponse = new ReplaySubject<Record<string, any>>(1);

  private readonly configCache = CacheBuilder.simple<string, Config>().build();

  private mobileLogo!: string;
  private desktopLogo!: string;
  private largeLogo!: string;
  private smallLogo!: string;
  private authLogo!: string;
  private profileSelector!: boolean;
  private _catColors: { [category: string]: string } = {};

  constructor(private http: HttpClient) {
    this.getUiConfig('_products_bg_url').subscribe(url => {
      document.documentElement.style.setProperty('--products-bg', `url("${url}")`);
    });
  }

  private _showAliceV2!: boolean;

  public get showAliceV2(): boolean {
    return this._showAliceV2;
  }

  private _simulationLabels: { lables: Array<string>; whitelist: boolean } | undefined;

  public get simulationLabels(): { lables: Array<string>; whitelist: boolean } | undefined {
    return deepCopy(this._simulationLabels);
  }

  private _archiveLabels: { lables: Array<string>; whitelist: boolean } | undefined;

  public get archiveLabels(): { lables: Array<string>; whitelist: boolean } | undefined {
    return deepCopy(this._archiveLabels);
  }

  private _folderLabels: { lables: Array<string>; whitelist: boolean } | undefined;

  public get folderLabels(): { lables: Array<string>; whitelist: boolean } | undefined {
    return deepCopy(this._folderLabels);
  }

  private _metricsLabels: { lables: Array<string>; whitelist: boolean } | undefined;

  public get metricsLabels(): { lables: Array<string>; whitelist: boolean } | undefined {
    return deepCopy(this._metricsLabels);
  }

  private _archivePageLabels: { lables: Array<string>; whitelist: boolean } | undefined;

  public get archivePageLabels(): { lables: Array<string>; whitelist: boolean } | undefined {
    return deepCopy(this._archivePageLabels);
  }

  private _aliceLabels: { lables: Array<string>; whitelist: boolean } | undefined;

  public get aliceLabels(): { lables: Array<string>; whitelist: boolean } | undefined {
    return deepCopy(this._aliceLabels);
  }

  private _showHelp?: boolean;

  public get showHelp(): boolean {
    return !!this._showHelp;
  }

  public get mobileLogoUrl(): string {
    return this.mobileLogo || '/assets/futura-logo-white.svg';
  }

  public get desktopLogoUrl(): string {
    return this.desktopLogo || '/assets/logo-sm.svg';
  }

  public get largeLogoUrl(): string {
    return this.largeLogo || this.mobileLogoUrl;
  }

  public get smallLogoUrl(): string {
    return this.smallLogo || this.desktopLogoUrl;
  }

  public get authLogoUrl(): string {
    return this.authLogo;
  }

  public get showProfileSelector(): boolean {
    return this.profileSelector;
  }

  public get categoryColors(): { [category: string]: string } {
    return deepCopy(this._catColors);
  }

  public init(): Observable<boolean> {
    const simulationsConfigUrl = `${environment.be_url}/config/${environment.platform}/simulations_config?no_cache=${Math.floor(Math.random() * 1000)}`;
    const uiConfigUrl = `${environment.be_url}/config/${environment.platform}/ui?no_cache=${Math.floor(Math.random() * 1000)}`;

    const simulationsConfig$ = this.http.get<{ [key: string]: any }>(simulationsConfigUrl).pipe(
      map(response => {
        this._simulationLabels = response.simulations_lables;
        this._archiveLabels = response.archive_lables;
        this._folderLabels = response.folder_lables;
        this._metricsLabels = response.metrics_labels;
        this._archivePageLabels = response.archive_page_lables;
        this._aliceLabels = response.alice_lables;

        this.simulationsResponse.next(response);
        return true;
      }),
      catchError(err => {
        console.error(err);
        return of(false);
      })
    );

    const uiConfig$ = this.http.get<{ [key: string]: any }>(uiConfigUrl).pipe(
      map(response => {
        this.mobileLogo = response._mobile_logo;
        this.desktopLogo = response._desktop_logo;
        this.largeLogo = response.logos?.large;
        this.smallLogo = response.logos?.small;
        this.authLogo = response._auth_logo;
        this.profileSelector = response._profile_selector == 'show' || response._profile_selector == undefined;
        this._showHelp = response._show_help == 'show' || response._show_help == undefined;
        this._showAliceV2 = response._alice_v2_active == 'true';
        this._catColors = response._colors.categories;
        this.uiResponse.next(response);
        return true;
      }),
      catchError(err => {
        console.error(err);
        return of(false);
      })
    );

    return forkJoin([simulationsConfig$, uiConfig$]).pipe(map(results => results.every(result => result)));
  }

  public getSimulationConfig<K extends ConfigSimulationName>(name: K): Observable<ConfigSimulationModel[K]> {
    return this.configSimulationCache.get(name, () => {
      return this.simulationsResponse.asObservable().pipe(map(result => result[name]));
    }) as Observable<ConfigSimulationModel[K]>;
  }

  public getUiConfig<K extends ConfigUiName>(name: K): Observable<ConfigUiModel[K]> {
    return this.configUiCache.get(name, () => {
      return this.uiResponse.asObservable().pipe(map(result => result[name]));
    }) as Observable<ConfigUiModel[K]>;
  }

  public getConfig(): Observable<Config> {
    return this.configCache.get('CACHE', () => {
      const url = environment.be_url + '/config/' + environment.platform + '/config' + '?no_cache=' + Math.floor(Math.random() * 1000);

      return this.http.get<Config>(url);
    });
  }
}
